/**
 * Register an object or function with the nv namespace. Creates a global `window.nv` namespaces with subpaths added
 * @param scope {string}
 * @param obj {unknown}
 */
export function register(scope, obj){
  if (typeof window.nv === 'undefined') {
    window.nv = {register};
  }

  if (typeof window.nv[scope] !== 'undefined') {
    console.warn(`[register] ${scope} is already registered`);
    return;
  }

  window.nv[scope] = obj;
}
