import Cookies from "js-cookie";

function buildCookieDomain() {
  const domainArray = location.hostname ? location.hostname.split('.') : [];
  return `.${domainArray.slice(-2).join(".")}`;
}

function handleError(e) {
  console.log(e);
}

document.addEventListener('DOMContentLoaded', function () {
  const countryCookieName = 'nv_country_code';
  const country = Cookies.get(countryCookieName);
  if (country !== undefined) {
    return;
  }

  fetch('https://geoip.svc.nvidia.com/json/')
    .then(response => response.json())
    .then(({country_code}) => Cookies.set(countryCookieName, country_code, { domain: buildCookieDomain() }))
    .catch(e => handleError);
});
