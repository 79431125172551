import React from 'react';
import ReactDOM from 'react-dom';
import Recommender from '../components/Recommender';

const components = {
  recommender: 'Recommender',
};

/**
 * Initializes the recommender component.
 *
 * @param {HTMLElement} rootElement
 *   HTML container.
 * @param {Object} options
 *   Props.
 * @param {String} options.title
 *   Component title.
 * @param {String} options.headerAlignment
 *   Header alignment (left | center). Left by default.
 * @param {String} options.layout
 *   Layout (3-cols | 4-cols). Default: 4-cols
 * @param {Array} options.recommendations
 *   Recommendations list.
 * @param {Boolean} options.overflow
 *   Overflow mode. Default - TRUE.
 * @param {Boolean} options.showGreenElement
 *   Green element flag. Default - FALSE.
 */
const initializeRecommenderComponent = (rootElement, options) => {
  const defaultOptions = {
    title: '',
    headerAlignment: 'left',
    layout: '4-cols',
    recommendations: [],
    overflow: true,
    showGreenElement: false,
  };
  const props = {...defaultOptions, ...options}
  try {
    ReactDOM.render(<Recommender {...props}/>, rootElement);
  } catch (e) {
    console.warn('Failed to mount Recommender component.');
  }
};

/**
 * Component initializer.
 *
 * @param {Object} config
 *   Config.
 * @param {String} config.target
 *   Target element selector.
 * @param {String} config.component
 *   Component ID.
 * @param {Object} config.options
 *   Components props.
 */
window.initializeComponent = function (config) {
  const {target, component, options = {}} = config;
  if (!target || !component) {
    return;
  }

  const rootElement = document.querySelector(target);
  if (!rootElement) {
    console.warn(`Unable to initialize component; Element "${target}" not found.`);
    return;
  }

  rootElement.innerHTML = '';
  switch (component) {
    case components.recommender:
      initializeRecommenderComponent(rootElement, options);
      break;
  }
}
