window.modalContainer = null;

/**
 *
 * @param {string} obj link object
 */
const showEulaModal = (obj) => {
  if (modalContainer !== null) {
    modalContainer.remove();
  }

  let link = obj.getAttribute("href");
  let doc = obj.getAttribute("data-eula");

  modalContainer = document.createElement('div');
  modalContainer.innerHTML = `
  <div class="modal" tabindex="-1">
    <div class="modal-dialog modal-xl" style="height: 90%">
      <div class="modal-content" style="height: 100%;">
        <div class="modal-header">
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <iframe style="width: 100%;height: 100%;" src="${doc}"></iframe>
        </div>
        <div class="modal-footer">
          <a id="accept-eula" class="btn btn-cta" href="${link}">Accept</a>
          <button type="button" class="btn btn-cta--light" data-bs-dismiss="modal">Decline</button>
        </div>
      </div>
    </div>
  </div>
`;

  document.body.append(modalContainer);

  let modal = new bootstrap.Modal(modalContainer.querySelector('.modal'));
  modal.show();

  document.addEventListener('click', function(event) {
    if (event.target.matches('#accept-eula')) {
      modal.hide();
    }
  }, false);
}

document.addEventListener('click', function(event) {
  if (event.target.matches('a[data-eula]') && typeof bootstrap === 'object') {
    event.preventDefault();
    showEulaModal(event.target);
  }
}, false);
