import Cookies from 'js-cookie'

document.addEventListener("DOMContentLoaded", function() {
  function getQueryVariable(variable){
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] == variable){return pair[1];}
    }
    return(false);
  }

  function getReferrerQueryVariable(variable){
    var referrer = document.referrer;
    var query = referrer.split('?')[1] || '';
    var vars = query.split("&");
    for (var i=0;i<vars.length;i++) {
      var pair = vars[i].split("=");
      if(pair[0] == variable){return pair[1];}
    }
    return(false);
  }

  var lsCookieName = "SESSls";
  var lsdCookieName = "SESSlsd";
  var ncidCookieName = "SESSncid";

  var lsQueryParamName = "ls";
  var lsdQueryParamName = "lsd";
  var ncidQueryParamName = "ncid";

  // Form the domain string to be used when creating the "ls" cookie.
  var domainArray = document.domain ? document.domain.split(".") : []; // Split domain string into an array.
  var domainString = "." + domainArray.slice(-2).join("."); // Concatenate the last two items in the array to form something like "mellanox.com" essentially chopping off the "www".
  var documentReferrer = document.referrer ? document.referrer : "";

  // Set up some variables for passing as cookie parameters.
  var cookieString = ""; // Set the cookie value.
  var path = ";path=/";
  var age = ";max-age=" + (60 * 60 * 24 * 90); // Set the duration of the cookie in seconds.
  var domain = ";domain=" + domainString; // Set the domain of the cookie.

  // Set the "ncid" cookie to the value of the "ncid" parameter.
  ncid = getQueryVariable(ncidQueryParamName) ? getQueryVariable(ncidQueryParamName) : undefined;

  // If "ncid" param is not available, default to the sc_cmp cookie which is set by nv.com, etc.
  if (typeof ncid === 'undefined') {
    var ncid = Cookies.get('sc_cmp'); // most recent ncid
  }

  if (ncid !== undefined) {
    var str = ncidCookieName + '=' + ncid;
    document.cookie = str + path + domain + age;
  }

  // Set "ls" cookie to the value of the "ls" parameter in the URL if it exists.
  var lsValue = getQueryVariable(lsQueryParamName) ? getQueryVariable(lsQueryParamName) : "";
  if (lsValue !== "") {
    cookieString = lsCookieName + "=" + encodeURIComponent(lsValue); // Set the cookie value.
    document.cookie = cookieString + path + domain + age; // Form the string input in the expected format and set the cookie.
    // If the user is coming from a search engine organically,
    // then set the "ls" cookie accordingly.
  } else if (documentReferrer !== "") {
    var lsdValueSEO = window.location.href;
    // Check to see if the referrer domain belongs to Google, Bing, Yahoo or Ask.
    // Since Google is using ccTLDs but Yahoo, Ask and Bing do not, there is a difference in how they are tested.
    if (documentReferrer.search("www.google.") != -1) {
      cookieString = lsCookieName + "=gseo"; // Set the cookie value.
    } else if (documentReferrer.search("bing.com") != -1) {
      cookieString = lsCookieName + "=bseo"; // Set the cookie value.
    } else if (documentReferrer.search("ask.com") != -1) {
      cookieString = lsCookieName + "=aseo"; // Set the cookie value.
    } else if (documentReferrer.search("yahoo.com") != -1) {
      cookieString = lsCookieName + "=yseo"; // Set the cookie value.
    } else {
      var lsCookieValue = Cookies.get(lsCookieName);
      if (typeof lsCookieValue === 'undefined') {
        if (documentReferrer.search("developer.nvidia.com") != -1) {
          cookieString = lsCookieName + "=website"; // Set the cookie value.
          lsdValueSEO = documentReferrer;
        } else {
          cookieString = lsCookieName + "=ref";
          lsdValueSEO = 'REF-'+documentReferrer;
        }
      }
    }
    if (cookieString !== "") {
      document.cookie = cookieString + path + domain + age; // Form the string input in the expected format and set the cookie.
      cookieString = lsdCookieName + '=' + encodeURIComponent(lsdValueSEO.replace("https://", ""));
      document.cookie = cookieString + path + domain + age;
    }
  } else {
    var lsCookie = Cookies.get(lsCookieName);
    if (typeof lsCookie === 'undefined') {
      cookieString = lsCookieName + "=website";
      document.cookie = cookieString + path + domain + age;
      var lsdCookie = Cookies.get(lsdCookieName);
      if (typeof lsdCookie === 'undefined') {
        var lsdValueWeb = window.location.href;
        cookieString = lsdCookieName + '=' + encodeURIComponent(lsdValueWeb.replace("https://", ""));
        document.cookie = cookieString + path + domain + age;
      }
    }
  }

  // Always set the "lsd" cookie with any ppc value provided in the URL parameter if available.
  var lsdValue = getQueryVariable(lsdQueryParamName) ? getQueryVariable(lsdQueryParamName) : "";
  if (lsdValue !== "") {
    cookieString = lsdCookieName + "=" + encodeURIComponent(lsdValue); // Set the cookie value.
    document.cookie = cookieString + path + domain + age; // Form the string input in the expected format and set the cookie.

    // Else if no "lsd" parameter is passed in the URL, then set the "lsd" cookie value to
    // the search query string found in the referrer URL parameters.
    // If no search query string is found, then store the entire referrer URL.
  } else if (documentReferrer !== "") {
    // Now determine if the referrer is coming from a search engine.
    // Then look for a search query string in the URL (Parameter "q" for Google, Bing, Ask; Parameter "p" for Yahoo).
    // First we'll look for Google.
    if (documentReferrer.search("google") != -1 && getReferrerQueryVariable("q")) {
      cookieString = lsdCookieName + "=" + encodeURIComponent(getReferrerQueryVariable("q")); // Set the cookie value.
      document.cookie = cookieString + path + domain + age; // Form the string input in the expected format and set the cookie.

      // Now let's look for Bing.
    } else if (documentReferrer.search("bing.com") != -1 && getReferrerQueryVariable("q")) {
      cookieString = lsdCookieName + "=" + encodeURIComponent(getReferrerQueryVariable("q")); // Set the cookie value.
      document.cookie = cookieString + path + domain + age; // Form the string input in the expected format and set the cookie.

      // Now let's look for Yahoo.
    } else if (documentReferrer.search("yahoo.com") != -1 && getReferrerQueryVariable("p")) {
      cookieString = lsdCookieName + "=" + encodeURIComponent(getReferrerQueryVariable("p")); // Set the cookie value.
      document.cookie = cookieString + path + domain + age; // Form the string input in the expected format and set the cookie.

      // Now let's look for Ask.
    } else if (documentReferrer.search("ask.com") != -1 && getReferrerQueryVariable("q")) {
      cookieString = lsdCookieName + "=" + encodeURIComponent(getReferrerQueryVariable("q")); // Set the cookie value.
      document.cookie = cookieString + path + domain + age; // Form the string input in the expected format and set the cookie.

      // If no query string is found but referrer URL is still coming from search engine, then set cookie to entire referrer URL.
    } else if (documentReferrer.search("google") != -1 || documentReferrer.search("bing.com") != -1 || documentReferrer.search("yahoo.com") != -1 || documentReferrer.search("ask.com") != -1) {
      cookieString = lsdCookieName + "=" + encodeURIComponent(documentReferrer); // Set the cookie value.
      document.cookie = cookieString + path + domain + age; // Form the string input in the expected format and set the cookie.
    }
  }
});
